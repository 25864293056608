import { AgdirApiEnvironment, AgdirGoogleEnvironment, AgdirOneSignalEnvironment, Environment } from '@agdir/environment/domain';
import { weHaveSharedMfas } from './we-have-shared-mfas';
import { weHaveSharedVendors } from './we-have-shared-vendors';

export const environment = new Environment({ env: 'dev', production: false });
environment
	.register(
		new AgdirApiEnvironment({
			webHost: 'https://agdir.dev',
			host: 'https://v5.api.agdir.dev',
		}),
	)
	.register(
		new AgdirGoogleEnvironment({
			apiKey: 'AIzaSyAd79l2jlY5ekNi-oX7lpbJbtNt6iP9_1c',
			reCaptchaV3SiteKey: '6LftOU0hAAAAAGvXGVjMOi-PUUxs6luj9GNiWp5G',
			googleAnalyticsTrackingCode: '',
		}),
	)
	.register(new AgdirOneSignalEnvironment({ appId: 'e1dca594-83f7-4bbe-a3e3-5a0369f23aab' }));

weHaveSharedMfas(environment);
weHaveSharedVendors(environment);
